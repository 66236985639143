import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { blogPosts } from '../data/blogData';
import { Sun, Moon } from 'lucide-react';

import { Toggle } from "./ui/Toggle";


const Layout: React.FC = () => {
    const [darkMode, setDarkMode] = useState<boolean>(false);
    const navigate = useNavigate(); // React Router hook to programmatically navigate

    const uniqueTags = Array.from(new Set(blogPosts.flatMap(post => post.tags)));

    // Load dark mode preference from localStorage when component mounts
    useEffect(() => {
        const savedTheme = localStorage.getItem('theme');
        const isDark = savedTheme === 'dark';
        setDarkMode(isDark);
        document.documentElement.classList.toggle('dark', isDark); // Add dark class to <html>
    }, []);

    // Toggle dark mode and save the preference to localStorage
    const toggleDarkMode = () => {
        const newMode = !darkMode;
        setDarkMode(newMode);
        document.documentElement.classList.toggle('dark', newMode); // Toggle dark mode globally
        localStorage.setItem('theme', newMode ? 'dark' : 'light');
    };


    // Handle navigation to list view with filtering by tag
    const handleTagClick = (tag: string | null) => {
        navigate('/', { state: { selectedTag: tag } });
    };

    return (
        <div className={`min-h-screen ${darkMode ? "bg-black text-white" : "bg-white text-black"}`}>
            {/* Dark Mode Toggle */}

            <div className="text-center m-3">
                <Toggle onClick={toggleDarkMode}
                        className="border border-gray-600 rounded-lg"
                        aria-label={darkMode ? "Light Mode" : "Dark Mode"}>
                    {darkMode ? "Light Mode" : "Dark Mode"}
                    {/* Show sun icon in light mode and moon icon in dark mode */}
                    {darkMode ? (
                        <>
                            <Sun className="ml-2 w-6 h-6 text-yellow-400" /> {/* Sun icon */}
                        </>
                    ) : (
                        <>
                            <Moon className="ml-2 w-6 h-6 text-blue-400" /> {/* Moon icon */}
                        </>
                    )}
                </Toggle>
            </div>
            <header className="max-w-2xl mx-auto">
                <h1 className="text-3xl font-bold m-4"> Sean Gerhardt's website </h1>
                {/* Tags */}
                <hr className="border-gray-300 dark:border-gray-700 mb-4"/>
                <div className="flex space-x-4 mb-6">
                    <button onClick={() => handleTagClick(null)}>All</button>
                    {uniqueTags.map(tag => (
                        <button
                            key={tag}
                            onClick={() => handleTagClick(tag)}
                            className="hover:underline"
                        >
                            {tag}
                        </button>
                    ))}
                </div>
                <hr className="border-gray-300 dark:border-gray-700 mb-6"/>
            </header>

            {/* Main Content */}
            <main className="max-w-2xl mx-auto">
                <Outlet context={{ darkMode }} />
            </main>
        </div>
    );
};

export default Layout;
