import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypePrism from 'rehype-prism-plus';
import 'prismjs/themes/prism-tomorrow.css';
import { useOutletContext } from 'react-router-dom';

const BlogPost: React.FC = () => {
    const { postId } = useParams<{ postId: string }>();
    const [postContent, setPostContent] = useState<string | null>(null);
    const { darkMode } = useOutletContext<{ darkMode: boolean }>();

    // Load the markdown file for the post
    useEffect(() => {
        const fetchPostContent = async () => {
            try {
                const response = await fetch(`/content/post${postId}.md`);
                const text = await response.text();
                setPostContent(text);
            } catch (error) {
                console.error("Error fetching the post content:", error);
                setPostContent("Error loading the post.");
            }
        };

        fetchPostContent();
    }, [postId]);

    return (
        <article className={`prose dark:prose-invert max-w-2xl mx-auto`}>
            <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypePrism]}>
                {postContent}
            </ReactMarkdown>
        </article>
    );
};

export default BlogPost;
