import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { blogPosts, Post } from '../data/blogData';

const BlogList: React.FC = () => {
    const location = useLocation();
    const selectedTag = (location.state && location.state.selectedTag) || null; // Retrieve selected tag from state

    // Filter posts by selected tag
    const filteredPosts: Post[] = selectedTag
        ? blogPosts.filter(post => post.tags.includes(selectedTag))
        : blogPosts;
    // Sort posts by id in descending order
    const sortedPosts = filteredPosts.sort((a, b) => b.id - a.id);

    return (
        <div>
            {filteredPosts.length > 0 ? (
                sortedPosts.map(post => (
                    <div key={post.id} className="mb-4">
                        <Link to={`/post/${post.id}`} className="text-xl hover:underline block">
                            {post.title}
                        </Link>
                        <p className="text-sm text-gray-500">{post.date}</p>
                    </div>
                ))
            ) : (
                <p>No posts found for this tag.</p>
            )}
        </div>
    );
};

export default BlogList;
