import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import BlogList from './components/BlogList';
import BlogPost from './components/BlogPost';

const App: React.FC = () => {
  useEffect(() => {
    document.title = "Sean Gerhardt's blog";
  }, []);   
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<BlogList />} />
            <Route path="post/:postId" element={<BlogPost />} />
          </Route>
        </Routes>
      </BrowserRouter>
  );
};

export default App;
