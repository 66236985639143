// src/data/blogData.ts
export interface Post {
    id: number;
    title: string;
    tags: string[];
    date: string;
}

export const blogPosts: Post[] = [
    {
        id: 1,
        title: "Welcome",
        tags: [],
        date: "2024-07-12",
    },
    {
        id: 2,
        title: "Mob Programming",
        tags: ["go", "software-development"],
        date: "2024-07-24",
    },
    {
        id: 3,
        title: "Transpiled Go & The Awful German Language",
        tags: ["go", "software-development", "german"],
        date: "2024-07-30",
    },
    {
        id: 4,
        title: "The Wonderful World of Hashes",
        tags: ["go", "software-development"],
        date: "2024-08-1",
    },
    {
        id: 5,
        title: "Book Review - 100 Go Mistakes and How To Avoid Them by Teiva Harsanyi",
        tags: ["go", "book review", "software-development"],
        date: "2024-08-15",
    },
    {
        id: 6,
        title: "String Building The Complete Works of Shakespeare",
        tags: ["go", "software-development"],
        date: "2024-09-12",
    },

];
